import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      /* {
        label: 'Exchange',
        href: 'https://swap.scribeswap.xyz/#/swap',
      },
      {
        label: 'Liquidity',
        href: 'https://swap.scribeswap.xyz/#/pool',
      }, */
      {
        label: 'Bridge',
        href: 'https://scroll.io/bridge',
      },
    ],
  },
  /*  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: '/pools',
  }, */
  {
    label: 'IFO',
    icon: 'IfoIcon',
    href: '/ifo',
  },
  
  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   href: '/pools',
  // },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: '/lottery',
  // },
  /*  {
     label: 'NFT',
     icon: 'NftIcon',
     href: '/nft',
   }, */
  
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Docs',
        href: 'https://docs.scribeswap.xyz/',
      },
      {
        label: 'Chart',
        href: 'https://dexscreener.com/scroll',
      },
    ],
  },
]

export default config
