export default {
  cake: {
    56: '0xF952Fc3ca7325Cc27D15885d37117676d25BfdA6',
    534352: '0x6219Ccb901fEc6e29DC87aE1558318CdCd1a06f2',
  },
  masterChef: {
    56: '0xe70E9185F5ea7Ba3C5d63705784D8563017f2E57',
    534352: '0x364C7A364fE5477CCC94e1a0cec1856f21bd1763',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    534352: '0x5300000000000000000000000000000000000004',
  },
  lottery: {
    56: '',
    534352: '',
  },
  lotteryNFT: {
    56: '',
    534352: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    534352: '0x479D2f7744b37FEAAF4981fE09a713BA863369eA',
  },
  USDC: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    534352: '0x06eFdBFf2a14a7c8E15944D1F4A48F9F95F663A4',
  },
}
