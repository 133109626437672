import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  /* {
    pid: 0,
    risk: 5,
    lpSymbol: 'SCRIBE-ETH LP',
    lpAddresses: {
      534352: '0x1B044593a78E374bD0E558Aa6633D2ff13fD5Bb7',
      56: '0x1B044593a78E374bD0E558Aa6633D2ff13fD5Bb7',
    },
    tokenSymbol: 'SCRIBE',
    tokenAddresses: {
      534352: '0x6219Ccb901fEc6e29DC87aE1558318CdCd1a06f2',
      56: '0x1B044593a78E374bD0E558Aa6633D2ff13fD5Bb7',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 1,
    risk: 5,
    lpSymbol: 'SCRIBE-USDC LP',
    lpAddresses: {
      534352: '0xE348C0f48f67d54906090513387cc888821Ed3FD',
      56: '0x6219Ccb901fEc6e29DC87aE1558318CdCd1a06f2',
    },
    tokenSymbol: 'SCRIBE',
    tokenAddresses: {
      534352: '0x6219Ccb901fEc6e29DC87aE1558318CdCd1a06f2',
      56: '0x6219Ccb901fEc6e29DC87aE1558318CdCd1a06f2',
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
  }, */

   {
    pid: 0,
    risk: 3,
    lpSymbol: 'ETH-USDC LP',
    lpAddresses: {
      534352: '0x92878dE0F0b1e6AA85614e13F67A366499F03eeA',
      56: '0xE56043671df55dE5CDf8459710433C10324DE0aE',
    },
    tokenSymbol: 'ETH',
    tokenAddresses: {
      534352: '0x5300000000000000000000000000000000000004',
      56: '0xE56043671df55dE5CDf8459710433C10324DE0aE',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.USDC,
  },
    {
        pid: 1,
        risk: 3,
        lpSymbol: 'ETH-USDT LP',
        lpAddresses: {
            534352: '0xb870fb52CAbd762ac71581C743120c923df961D4',
            56: '0xE56043671df55dE5CDf8459710433C10324DE0aE',
        },
        tokenSymbol: 'ETH',
        tokenAddresses: {
            534352: '0x5300000000000000000000000000000000000004',
            56: '0xE56043671df55dE5CDf8459710433C10324DE0aE',
        },
        quoteTokenSymbol: QuoteToken.BNB,
        quoteTokenAdresses: contracts.USDC,
    },
  {
    pid: 2,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'SCRIBE',
    lpAddresses: {
      534352: '0x6219Ccb901fEc6e29DC87aE1558318CdCd1a06f2',
      56: '0xE56043671df55dE5CDf8459710433C10324DE0aE', // EGG-DAI LP
    },
    tokenSymbol: 'SCRIBE',
    tokenAddresses: {
      534352: '0x6219Ccb901fEc6e29DC87aE1558318CdCd1a06f2',
      56: '0xE56043671df55dE5CDf8459710433C10324DE0aE',
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
  },
  {
    pid: 3,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'ETH',
    lpAddresses: {
      534352: '0x5300000000000000000000000000000000000004',
      56: '0xE56043671df55dE5CDf8459710433C10324DE0aE', // DAI-DAI LP
    },
    tokenSymbol: 'ETH',
    tokenAddresses: {
      534352: '0x5300000000000000000000000000000000000004',
      56: '0xE56043671df55dE5CDf8459710433C10324DE0aE',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
 {
  pid: 4,
  risk: 1,
  isTokenOnly: true,
  lpSymbol: 'USDC',
  lpAddresses: {
    534352: '0x06eFdBFf2a14a7c8E15944D1F4A48F9F95F663A4',
    56: '0xE56043671df55dE5CDf8459710433C10324DE0aE', // USDC-DAI LP
  },
  tokenSymbol: 'USDC',
  tokenAddresses: {
    534352: '0x06eFdBFf2a14a7c8E15944D1F4A48F9F95F663A4',
    56: '0xE56043671df55dE5CDf8459710433C10324DE0aE',
  },
  quoteTokenSymbol: QuoteToken.DAI,
  quoteTokenAdresses: contracts.USDC,
},
    {
        pid: 5,
        risk: 1,
        isTokenOnly: true,
        lpSymbol: 'USDT',
        lpAddresses: {
            534352: '0xf55BEC9cafDbE8730f096Aa55dad6D22d44099Df',
            56: '0xE56043671df55dE5CDf8459710433C10324DE0aE', // USDC-DAI LP
        },
        tokenSymbol: 'USDT',
        tokenAddresses: {
            534352: '0xf55BEC9cafDbE8730f096Aa55dad6D22d44099Df',
            56: '0xE56043671df55dE5CDf8459710433C10324DE0aE',
        },
        quoteTokenSymbol: QuoteToken.DAI,
        quoteTokenAdresses: contracts.USDC,
    },
/* {
  pid: 6,
  risk: 2,
  isTokenOnly: true,
  lpSymbol: 'WPLS',
  lpAddresses: {
    534352: '0xE56043671df55dE5CDf8459710433C10324DE0aE',
    56: '0xE56043671df55dE5CDf8459710433C10324DE0aE', // ETH-DAI LP
  },
  tokenSymbol: 'WPLS',
  tokenAddresses: {
    534352: '0xE56043671df55dE5CDf8459710433C10324DE0aE',
    56: '0xE56043671df55dE5CDf8459710433C10324DE0aE',
  },
  quoteTokenSymbol: QuoteToken.BNB,
  quoteTokenAdresses: contracts.wbnb,
},
{
  pid: 7,
  risk: 1,
  isTokenOnly: true,
  lpSymbol: 'DAI',
  lpAddresses: {
    534352: '0xE56043671df55dE5CDf8459710433C10324DE0aE',
    56: '0xE56043671df55dE5CDf8459710433C10324DE0aE', // DAI-DAI LP
  },
  tokenSymbol: 'DAI',
  tokenAddresses: {
    534352: '0xE56043671df55dE5CDf8459710433C10324DE0aE',
    56: '0xE56043671df55dE5CDf8459710433C10324DE0aE',
  },
  quoteTokenSymbol: QuoteToken.DAI,
  quoteTokenAdresses: contracts.DAI,
},
{
  pid: 7,
  risk: 1,
  isTokenOnly: true,
  lpSymbol: 'ETH',
  lpAddresses: {
    534352: '0xE56043671df55dE5CDf8459710433C10324DE0aE',
    56: '0xE56043671df55dE5CDf8459710433C10324DE0aE', // DAI-DAI LP
  },
  tokenSymbol: 'ETH',
  tokenAddresses: {
    534352: '0xE56043671df55dE5CDf8459710433C10324DE0aE',
    56: '0xE56043671df55dE5CDf8459710433C10324DE0aE',
  },
  quoteTokenSymbol: QuoteToken.BNB,
  quoteTokenAdresses: contracts.DAI,
}, */
  /* {
    pid: 8,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'USDC',
    lpAddresses: {
      534352: '0xE56043671df55dE5CDf8459710433C10324DE0aE',
      56: '0xE56043671df55dE5CDf8459710433C10324DE0aE', // USDC-DAI LP
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      534352: '0xE56043671df55dE5CDf8459710433C10324DE0aE',
      56: '0xE56043671df55dE5CDf8459710433C10324DE0aE',
    },
    quoteTokenSymbol: QuoteToken.DAI,
    quoteTokenAdresses: contracts.DAI,
  },
  {
    pid: 9,
    risk: 4,
    isTokenOnly: true,
    lpSymbol: 'PLSX',
    lpAddresses: {
      534352: '0xE56043671df55dE5CDf8459710433C10324DE0aE',
      56: '0xE56043671df55dE5CDf8459710433C10324DE0aE', // CAKE-DAI LP
    },
    tokenSymbol: 'PLSX',
    tokenAddresses: {
      534352: '0xE56043671df55dE5CDf8459710433C10324DE0aE',
      56: '0xE56043671df55dE5CDf8459710433C10324DE0aE',
    },
    quoteTokenSymbol: QuoteToken.DAI,
    quoteTokenAdresses: contracts.DAI,
  },
  {
    pid: 10,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'HEX',
    lpAddresses: {
      534352: '0xE56043671df55dE5CDf8459710433C10324DE0aE',
      56: '0xE56043671df55dE5CDf8459710433C10324DE0aE', // BSCX-DAI LP
    },
    tokenSymbol: 'HEX',
    tokenAddresses: {
      534352: '0xE56043671df55dE5CDf8459710433C10324DE0aE',
      56: '0xE56043671df55dE5CDf8459710433C10324DE0aE',
    },
    quoteTokenSymbol: QuoteToken.DAI,
    quoteTokenAdresses: contracts.DAI,
  }, */
]

export default farms
